import { useEffect, useRef } from 'react'
import { v4 } from 'uuid'
import { useNotificationContext } from './notification-context'
import { NotificationType } from './types'

type OptionsType = Omit<NotificationType, 'id'> & { condition: boolean }

export const useNotification = ({ condition, type, content }: OptionsType) => {
  const { showNotification, hideNotification } = useNotificationContext()

  const id = useRef<string>()

  useEffect(() => {
    if (condition && !id.current) {
      id.current = v4()
      showNotification({ id: id.current, type, content })
    }

    return () => {
      if (id.current) hideNotification(id.current)
    }
  }, [])
}
