import { motion, useAnimate } from 'motion/react'
import Image from 'next/image'
import { useEffect } from 'react'
import { useNotificationContext } from './notification-context'
import { NotificationType } from './types'

const PARAMS = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1 },
  transition: { delay: 2, type: 'spring', stiffness: 400, damping: 30, duration: 0.3 },
}

const DEFAULT_DURATION = 10000

type PropsType = NotificationType & { id: string }

export function Notification({ duration = DEFAULT_DURATION, content, id }: PropsType) {
  const [scope, animate] = useAnimate()
  const { hideNotification } = useNotificationContext()

  const hide = async () => {
    await animate(scope.current, PARAMS.initial)
    hideNotification(id)
  }

  useEffect(() => {
    const timeout = setTimeout(hide, duration)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <motion.div
      ref={scope}
      initial={PARAMS.initial}
      animate={PARAMS.animate}
      transition={PARAMS.transition}
      className="rounded-full px-4 py-2 bg-black shadow-md text-white w-fit"
    >
      <div className="flex items-center justify-center gap-2">
        <div>
          <Image src={'/icons/error.svg'} alt={'add to favorites icon'} width={20} height={20} />
        </div>
        {content}
      </div>
    </motion.div>
  )
}
