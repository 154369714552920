import { extractPlanInfoById } from '@/constants'
import { cn } from '@lib/shadcn'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { $userProfile } from '../model'

export function SessionsCountProgress() {
  const t = useTranslations()

  const [user] = useUnit([$userProfile])

  if (!user) return

  const plan = extractPlanInfoById(user.plan_id)
  const userDevices = user.sessions.length
  const planDevices = plan?.sessions ?? 1
  const progress = userDevices >= planDevices ? 100 : (userDevices / planDevices) * 100

  return (
    <>
      <div className="w-full">
        <div className="h-2 w-full rounded bg-gray-300">
          <div
            className={cn(`h-2 rounded bg-gradient-to-r  transition-all duration-300 `, {
              'from-blue-500 to-blue-300': userDevices < planDevices,
              'from-red-300 to-red-500': userDevices >= planDevices,
            })}
            style={{ width: progress + '%' }}
          ></div>
        </div>
      </div>
      <span className="mt-1 block text-gray-500">
        {t.rich('sessions', {
          count: userDevices > planDevices ? userDevices : planDevices - userDevices,
          max: planDevices,
          important: (chunks) => <b className="font-medium text-black">{chunks}</b>,
        })}
      </span>
    </>
  )
}
