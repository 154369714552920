import { useTranslations } from 'next-intl'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

type SeoProps = Readonly<
  Partial<{
    title: string
    description: string
    keywords: string
    structureData: string
    scriptSrc: string
    area: string
    canonical: boolean
    structure: string
  }>
>

export const Seo = ({
  title,
  description,
  keywords,
  structureData,
  structure,
  scriptSrc,
  area,
  canonical = false,
}: SeoProps) => {
  const { asPath, query } = useRouter()
  const t = useTranslations()

  const currentLocateImage = useMemo(
    () =>
      `https://statsnet.co${
        (query.id && (Number.isNaN(parseInt(query.id as string)) ? asPath : `/getimg/${query.state}/${query.id}`)) ||
        asPath
      }`,
    [asPath, query.state, query.id],
  )

  const currentLocate = useMemo(() => `https://statsnet.co${asPath}`, [asPath])

  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0" />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://statsnet.co${asPath}",
              "potentialAction": [{
                  "@type": "SearchAction",
                  "target": "https://statsnet.co/search/kz/{search_term_string}",
                  "query-input": "required name=search_term_string"
              }]
            }`,
        }}
      />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <title>{title || `Statsnet — ${t('seo-title')}`}</title>
      <meta httpEquiv="x-dns-prefetch-control" content="on" />
      <meta
        name="Description"
        content={
          description ||
          'Statsnet – это сервис, разработанный для тех, кто хочет работать только с надёжными контрагентами. Проявите должную осмотрительность и проверяйте своих контрагентов по 37 факторам риска.'
        }
      />
      <meta name="Keywords" content={keywords} />
      <meta property="og:type" content="organization" />
      <meta property="og:title" content={title || `Statsnet — ${t('seo-title')}`} />
      <meta property="og:image" content={currentLocateImage || 'https://statsnet.co'} />
      <meta
        property="og:description"
        content={
          description ||
          'Statsnet – это сервис, разработанный для тех, кто хочет работать только с надёжными контрагентами. Проявите должную осмотрительность и проверяйте своих контрагентов по 37 факторам риска.'
        }
      />
      {asPath?.includes('auth') ? <link rel="canonical" href={`https://statsnet.co/auth`} /> : null}
      <meta property="og:url" content={currentLocate || 'https://statsnet.co'} />
      <meta property="twitter:title" content={title || 'Statsnet — поиск и проверка контрагентов по всему миру'} />
      <meta
        property="twitter:description"
        content={
          description ||
          'Statsnet – это сервис, разработанный для тех, кто хочет работать только с надёжными контрагентами. Проявите должную осмотрительность и проверяйте своих контрагентов по 37 факторам риска.'
        }
      />
      <meta property="twitter:url" content={currentLocate || 'https://statsnet.co'} />
      <meta property="twitter:image" content={currentLocateImage || 'https://statsnet.co'} />
      {structure !== 'se' && <meta name="robots" content="noindex, nofollow" />}
      {structureData && <script async type="application/ld+json" dangerouslySetInnerHTML={{ __html: structureData }} />}
      {scriptSrc && <script async src={scriptSrc} />}

      {area && <link href={`https://statsnet.co/states/kz/${area}`} />}

      {canonical && <link rel="canonical" href={currentLocate} />}
    </Head>
  )
}
