import { Nullable } from '@/T'
import { $signUpInProcess, $signupErrorData } from '@/api'
import { SafeView } from '@/features'
import { AuthWithGoogle } from '@/process/auth-with-goolge'
import { Back, Button, CircularProgress, FloatInput } from '@/ui'
import clsx from 'clsx'
import { Field as FieldType, useField, useForm } from 'effector-forms'
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { $$signUpForm, $redirectURI, setRedirectURI } from 'src/screens/auth/sign-up/model'
import { FloatInputProps } from 'src/shared/ui/form/float-input'

const styles = {
  termsAndPolicy: 'text-blue-500 cursor-pointer transition-all',
  suggestionItem: 'px-4 py-2 cursor-pointer text-lg',
  suggestionList: 'bg-white border border-gray-300 rounded-md mt-1 absolute z-50 w-full max-w-sm',
  suggestionHighlighted: 'bg-blue-100 text-blue-500',
  suggestionText: 'text-base',
  suggestionMatch: 'text-blue-500',
}

interface SignUpFormErrors {
  name: Nullable<string>
  phone: Nullable<string>
  email: Nullable<string>
  company: Nullable<string>
  password: Nullable<string>
}

const Field = ({
  field,
  label,
  type,
  name,
  className,
  onChange: customOnChange,
  value: customValue,
}: { field: FieldType<any> } & FloatInputProps) => {
  const { onChange, value } = useField(field)

  return (
    <FloatInput
      onChange={(e) => {
        onChange(e.target.value)
        customOnChange?.(e)
      }}
      value={customValue || value}
      label={label}
      type={type}
      name={name}
      className={className}
    />
  )
}

export const SignUpScreen = () => {
  const t = useTranslations()
  const locale = useLocale()
  const router = useRouter()
  const [redirectURI, setToRedirectURI] = useUnit([$redirectURI, setRedirectURI])
  const [emailSuggestions, setEmailSuggestions] = useState<any[]>([])
  const [suggestionHighlighted, setSuggestionHighlighted] = useState<number | null>(null)
  const [emailValue, setEmailValue] = useState('')
  const [nameValue, setNameValue] = useState('')
  const [restFieldsShown, setRestFieldsShown] = useState(false)

  const fetchEmailSuggestions = async (query: string) => {
    if (query.includes('@')) {
      try {
        const response = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Token c980290aa19a7a7ebe1a81667d6d3d331264f271',
          },
          body: JSON.stringify({ query }),
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        const suggestions = data.suggestions || []
        setEmailSuggestions(suggestions.slice(0, 4)) // Limit to 4 suggestions
      } catch (error) {
        console.error('Error fetching email suggestions:', error)
        setEmailSuggestions([])
      }
    } else {
      setEmailSuggestions([])
    }
  }

  if (router.query?.redirect_uri) {
    setToRedirectURI(decodeURI((router.query.redirect_uri as string) || redirectURI))
  }

  const { submit, hasError, fields, errorText } = useForm($$signUpForm)

  const [signupError, loading] = useUnit([$signupErrorData, $signUpInProcess])

  const errors = Object.keys(fields)
    .map((key) => (hasError(key as keyof SignUpFormErrors) ? errorText(key as keyof SignUpFormErrors) : null))
    .filter(Boolean)

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setNameValue(value)
    if (value.length > 0) {
      setRestFieldsShown(true)
    }
  }

  const handleSuggestionClick = (suggestion: string) => {
    setEmailValue(suggestion)
    $$signUpForm.fields.email.onChange(suggestion)
    setEmailSuggestions([])
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setSuggestionHighlighted((prevIndex) =>
        prevIndex === null ? 0 : Math.min(prevIndex + 1, emailSuggestions.length - 1),
      )
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setSuggestionHighlighted((prevIndex) => (prevIndex === null ? 0 : Math.max(prevIndex - 1, 0)))
    } else if (e.key === 'Enter' && suggestionHighlighted !== null) {
      e.preventDefault()
      handleSuggestionClick(emailSuggestions[suggestionHighlighted].value)
    }
  }

  const signUpTermsAndPolicy = t.raw('sign_up_under_text')

  return (
    <main className="container flex h-screen flex-col items-center justify-center gap-y-3 relative">
      <Link locale={locale} href={{ pathname: '/', query: router.query }} className="font-aeroport text-2xl">
        Statsnet
      </Link>
      <p className="font-stem text-2xl">{t('create_account')}</p>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
        className="w-full max-w-sm"
      >
        <Field
          field={$$signUpForm.fields.email}
          label={t('email')}
          type="email"
          name="email"
          className="rounded-t-md border-b-transparent"
          onChange={(e) => {
            const value = e.target.value
            setEmailValue(value)
            $$signUpForm.fields.email.onChange(value)
            fetchEmailSuggestions(value)
          }}
          onKeyDown={handleKeyDown}
          value={emailValue}
        />

        {emailSuggestions.length > 0 && (
          <ul className={styles.suggestionList}>
            {emailSuggestions.map((suggestion: any, index) => {
              const suggestionText = suggestion.value
              const match = emailValue.toLowerCase()
              const parts = suggestionText.split(new RegExp(`(${match})`, 'gi'))

              return (
                <li
                  key={index}
                  className={clsx(styles.suggestionItem, {
                    [styles.suggestionHighlighted]: suggestionHighlighted === index,
                  })}
                  onClick={() => handleSuggestionClick(suggestionText)}
                  onMouseEnter={() => setSuggestionHighlighted(index)}
                  onMouseLeave={() => setSuggestionHighlighted(null)}
                >
                  {parts.map((part: string, i: number) => (
                    <span
                      key={i}
                      className={clsx(styles.suggestionText, {
                        [styles.suggestionMatch]: part.toLowerCase() === match,
                      })}
                    >
                      {part}
                    </span>
                  ))}
                </li>
              )
            })}
          </ul>
        )}

        <Field
          field={$$signUpForm.fields.name}
          label={t('first_last_name')}
          type="text"
          name="name"
          className={clsx('', {
            'rounded-b-md': !restFieldsShown,
            'border-b-transparent': restFieldsShown,
          })}
          onChange={onNameChange}
          value={nameValue}
        />
        <div
          className={clsx(`transition-height overflow-hidden duration-1000 ease-out`, {
            'max-h-96': restFieldsShown,
            'max-h-0': !restFieldsShown,
          })}
        >
          <Field
            field={$$signUpForm.fields.phone}
            label={t('phone')}
            type="tel"
            name="phone"
            className="border-b-transparent"
          />
          <Field
            field={$$signUpForm.fields.company}
            label={t('company_name')}
            type="text"
            name="company"
            className="border-b-transparent"
          />
          <Field
            field={$$signUpForm.fields.password}
            label={t('create_password')}
            type="password"
            name="password"
            className="rounded-b-md"
          />
        </div>

        <div className="mt-5 h-[52px]">
          <Button
            className="h-[52px] w-full bg-blue-500 text-md rounded-md focus:outline-none disabled:bg-gray-400"
            type="submit"
            disabled={errors.length > 0 || loading}
          >
            <SafeView
              for={!loading}
              otherwise={
                <div className="mx-auto h-4 w-4">
                  <CircularProgress color="white" />
                </div>
              }
            >
              {t('register')}
            </SafeView>
          </Button>
        </div>

        <div className="mt-4">
          <AuthWithGoogle />
        </div>

        <div className="mt-2 flex flex-col gap-x-1 text-red-500">
          {errors.map((error) => error && <p key={error}>{t(error)}</p>)}
          {signupError && signupError.message && <p>{t(signupError.message.split(' ').join('_'))}</p>}
        </div>
      </form>

      <div className="w-full max-w-sm text-left">
        <p className="self-start text-gray-500">{t('registered_now')}</p>
        <p className="text-blue-500 hover:text-blue-600">
          <Link
            locale={locale}
            href={{
              pathname: '/auth/login',
              query: router.query,
            }}
          >
            {t('login')}
          </Link>
        </p>
        <p className="text-blue-500 hover:text-blue-600">
          <Link locale={locale} href={{ pathname: '/auth/forget-password', query: router.query }}>
            {t('forget_password')}
          </Link>
        </p>

        <p className="max-w-lg pt-5 text-left">
          <span className="text-gray-500">{signUpTermsAndPolicy.agreement}&nbsp;</span>
          <Link locale={locale} href="/terms">
            <span className={styles.termsAndPolicy}>{signUpTermsAndPolicy.terms}&nbsp;</span>
          </Link>
          <span className="text-gray-500">{signUpTermsAndPolicy.and}&nbsp;</span>
          <Link locale={locale} href="/policy">
            <span className={styles.termsAndPolicy}>{signUpTermsAndPolicy.policy}</span>
          </Link>
        </p>
      </div>
      <Back />
    </main>
  )
}
