import { resendSmsFx } from '@/api'
import { createEvent, sample } from 'effector'

export const resendSms = createEvent()

sample({
  clock: resendSms,
  fn: () => ({}),
  target: resendSmsFx,
})
