import { RequestParams } from '@/api/model'
import { Nullable } from '@/T'

const CLIENT_FALLBACK_HOST = 'https://statsnet.co'

export const enum ROUTES {
  HOME = '/',
  PRICING = '/pricing',
  STATES = '/states',
  LOGIN = '/auth/login',
  SIGN_UP = '/auth/sign-up',
  COMPANY = '/companies',
  SEARCH = '/search',
  PERSONAL_AREA = '/me/personal-area',
}

export interface Subscription {
  created_at: string
  deleted_at: Nullable<string>
  description: string
  id: number
  months: number
  old_price: string
  price: number
  plan_id: number
  title: string
  updated_at: string
  checks?: number
  product_id?: number
  alternative?: number
}

export type PlanIDs = (typeof Plans)[number]['plan_id'] | 0

export const Plans = [
  {
    checks: 20,
    created_at: '2020-12-25T21:16:06.336462Z',
    deleted_at: null,
    description: '',
    product_id: 701435,
    id: 1,
    months: 1,
    old_price: '12180',
    price: 4990,
    plan_id: 1,
    alternative: 2,
    title: 'Basic',
    updated_at: '2020-12-25T21:16:06.336462Z',
    sessions: 1,
  },
  {
    checks: 20,
    created_at: '2020-12-25T21:16:06.336462Z',
    deleted_at: null,
    description: '',
    product_id: 701435,
    id: 2,
    months: 12,
    old_price: '60000',
    price: 49990,
    plan_id: 2,
    alternative: 1,
    title: 'Basic',
    updated_at: '2020-12-25T21:16:06.336462Z',
    sessions: 1,
  },
  {
    checks: 1000,
    created_at: '2020-12-25T21:16:06.336462Z',
    deleted_at: null,
    description: '',
    product_id: 701438,
    id: 3,
    months: 1,
    old_price: '83580',
    price: 39000,
    plan_id: 3,
    alternative: 4,
    title: 'Premium',
    updated_at: '2020-12-25T21:16:06.336462Z',
    sessions: 1,
  },
  {
    checks: 1000,
    created_at: '2020-12-25T21:16:06.336462Z',
    deleted_at: null,
    description: '',
    product_id: 701438,
    id: 4,
    months: 12,
    old_price: '500000',
    price: 399000,
    plan_id: 4,
    alternative: 3,
    title: 'Premium',
    updated_at: '2020-12-25T21:16:06.336462Z',
    sessions: 1,
  },
  {
    checks: 2500,
    created_at: '2020-12-25T21:16:06.336462Z',
    deleted_at: null,
    description: '',
    product_id: 701439,
    id: 5,
    months: 1,
    old_price: '99000',
    price: 99000,
    plan_id: 5,
    alternative: 6,
    title: 'Pro',
    updated_at: '2020-12-25T21:16:06.336462Z',
    sessions: 5,
  },
  {
    checks: 2500,
    created_at: '2020-12-25T21:16:06.336462Z',
    deleted_at: null,
    description: '',
    product_id: 701439,
    id: 6,
    months: 12,
    old_price: '990000',
    price: 990000,
    plan_id: 6,
    alternative: 5,
    title: 'Pro',
    updated_at: '2020-12-25T21:16:06.336462Z',
    sessions: 5,
  },
] as const

export const availablePlans = Plans as unknown as Subscription[]

export const availablePlansByTitle = Plans.reduce(
  (acc, { title, plan_id }) => ({
    ...acc,
    [title]: [...(acc[title] || []), plan_id],
  }),
  {} as Record<(typeof Plans)[number]['title'], PlanIDs[]>,
)

export const extractPlanInfoById = (id: number) => Plans.find(({ id: planId }) => planId === id)

export const filterKeys = (() => {
  const dummy: Required<RequestParams<'/business/search/highlights', 'post'>['filters']> = {
    'addresses.state': [],
    company_size: [],
    inactive: [],
    industry: [],
    jurisdiction: [],
    legal_form: [],
    main_industry_code: [],
    structure: [],
    fin_taxes: {},
    revenue: {},
    contact_type: {},
    incorporation_date: {},
  }

  return Object.keys(dummy) as Array<keyof typeof dummy>
})()

export const filterNameToTranslateMap: Record<
  (typeof filterKeys)[number] | string,
  Partial<{ placeholder: string; title: string }> & { type: 'range-double' | 'choice' | 'date' }
> = {
  'addresses.state': { title: 'regions', placeholder: 'regions', type: 'choice' },
  main_industry_code: { title: 'kind_of_activity', placeholder: 'activity_types', type: 'choice' },
  industry: { title: 'sector', placeholder: 'sector', type: 'choice' },
  inactive: { title: 'status', type: 'choice' },
  structure: { title: 'branch_filial', type: 'choice' },
  company_size: { title: 'numberOfEmployees', type: 'choice' },
  legal_form: { title: 'organizationForms', type: 'choice' },
  jurisdiction: { title: 'jurisdiction', type: 'choice' },
  fin_taxes: { title: 'taxes', placeholder: 'taxes', type: 'range-double' },
  revenue: { title: 'revenue', placeholder: 'revenue', type: 'range-double' },
  contact_type: { title: 'contacts', type: 'choice' },
  incorporation_date: { title: 'incorporation_date', placeholder: 'incorporation_date', type: 'date' },
}

export const multipleFacets = {
  company_size: [
    {
      value: 'large',
      name: 'large',
    },
    {
      value: 'medium',
      name: 'medium',
    },
    {
      value: 'small',
      name: 'small',
    },
  ],
  structure: [
    { value: 'le', name: 'Юридические лица' },
    { value: 'branch', name: 'Филиалы' },
  ],
  revenue: [
    {
      value: 0,
      name: 'min',
      count: 1,
    },
    {
      value: 0,
      name: 'max',
      count: 1,
    },
  ],
  fin_taxes: [
    {
      value: 0,
      name: 'min',
      count: 1,
    },
    {
      value: 0,
      name: 'max',
      count: 1,
    },
  ],
  incorporation_date: [
    {
      value: '',
      name: 'gte',
      count: 1,
    },
    {
      value: '',
      name: 'lte',
      count: 1,
    },
  ],
} as const

export const CLIENT_HOST = process.env.NEXT_PUBLIC_CLIENT_HOST || CLIENT_FALLBACK_HOST

export class Client {
  public static get host(): string {
    return CLIENT_HOST
  }
}
