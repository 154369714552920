import { $userProfile } from '@entities/user'
import * as Popover from '@radix-ui/react-popover'
import { useMask } from '@react-input/mask'
import { Calendar } from '@shared/ui/calendar'
import { useUnit } from 'effector-react'
import moment from 'moment'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'

const maskOptions = {
  mask: 'yyyy-mm-dd',
  replacement: { d: /\d/, m: /\d/, y: /\d/ },
  showMask: true,
}

export const FacetDateField = ({ field, sortedFacet }: { field: string; sortedFacet: FacetValues[] }) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [userProfile] = useUnit([$userProfile])

  const [gte, lte] = sortedFacet ?? []
  const [{ gteDate, lteDate }, setDate] = useState({ gteDate: gte.value, lteDate: lte.value })

  const isValid = (date: Date | string) => moment(new Date(date)).isValid()

  const updateDateFacet = useCallback(
    async (facet: string, value: [string, string]) => {
      if (searchParams.get(facet) === value.join(',')) return
      if (!value.filter((date) => isValid(date)).length) return
      if (!value.filter((date) => !!date).length) return

      const query = { ...router.query, [facet]: value.join(',') }

      await router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    [gte.value, lte.value, router, searchParams, userProfile?.plan_id],
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  const handleSelectGteDate = (type: 'gteDate' | 'lteDate') => (date?: Date) => {
    setDate((prevState) => ({ ...prevState, [type]: moment(date).format('YYYY-MM-DD') }))
  }

  useEffect(() => {
    const t = setTimeout(async () => {
      await updateDateFacet(field, [gteDate, lteDate])
    }, 500)

    return () => clearTimeout(t)
  }, [gteDate, lteDate, field, updateDateFacet])

  return (
    <div className="mb-2 flex py-2">
      <div className="flex w-full flex-col space-y-2">
        <div className="flex flex-row">
          <Popover.Root>
            <Popover.Trigger asChild>
              <input
                ref={useMask(maskOptions)}
                name="gteDate"
                placeholder="yyyy-mm-dd"
                className="text-md mx-0.5 flex w-full cursor-pointer items-center gap-2 rounded-md border border-[#dcdcdc] bg-transparent p-1 px-2 text-[#929292] outline-0 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-600"
                type="text"
                value={gteDate}
                onChange={handleInputChange}
              />
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content sideOffset={5} onOpenAutoFocus={(e) => e.preventDefault()}>
                <div className="flex flex-row space-x-2 bg-background rounded-md border border-[#dcdcdc">
                  <Calendar
                    mode="single"
                    selected={isValid(gteDate) ? moment(gteDate).toDate() : new Date()}
                    defaultMonth={isValid(gteDate) ? moment(gteDate).toDate() : new Date()}
                    onSelect={handleSelectGteDate('gteDate')}
                  />
                </div>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
          <div className="py-1 w-3">-</div>
          <Popover.Root>
            <Popover.Trigger asChild>
              <input
                ref={useMask(maskOptions)}
                name="lteDate"
                placeholder="yyyy-mm-dd"
                className="text-md mx-0.5 flex w-full cursor-pointer items-center gap-2 rounded-md border border-[#dcdcdc] bg-transparent p-1 px-2 text-[#929292] outline-0 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-600"
                type="text"
                value={lteDate}
                onChange={handleInputChange}
              />
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content sideOffset={5} onOpenAutoFocus={(e) => e.preventDefault()}>
                <div className="flex flex-row space-x-2 bg-background rounded-md border border-[#dcdcdc">
                  <Calendar
                    mode="single"
                    selected={isValid(lteDate) ? moment(lteDate).toDate() : new Date()}
                    defaultMonth={isValid(lteDate) ? moment(lteDate).toDate() : new Date()}
                    onSelect={handleSelectGteDate('lteDate')}
                  />
                </div>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        </div>
      </div>
    </div>
  )
}
