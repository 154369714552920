import * as Tabs from '@radix-ui/react-tabs'
import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { sumBy } from 'lodash'
import { useLocale, useTranslations } from 'next-intl'
import { useMemo } from 'react'

import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'

import { Placeholder, ShadcnButton } from '@/ui'
import { DataSection } from 'src/screens/company/ui/data-section'
import { $taxDeclarations } from 'src/screens/company/ui/finances/model'
import { Visualization } from 'src/screens/company/ui/finances/visualization'

import { dataToExcel } from '@shared/lib'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'
import { VirtualizedTable } from 'src/shared/ui/virtualized-table'

const tabRules = [
  {
    value: 'deductions',
    title: 'tax_deductions',
    forPerson: false,
  },
  {
    value: 'accruals',
    title: 'tax_accruals',
    forPerson: true,
  },
  {
    value: 'declarations',
    title: 'tax_declarations',
    forPerson: false,
  },
]

type TableData = {
  code: string | number
  name: string
  summary: number
  year: number

  [year: number]: number | string
}

const toTableTaxes = (lang: string, data: TableData[]) => {
  const years = Array.from(new Set(data.map((v) => v.year)))
  const formatter = new Intl.NumberFormat(lang)
  data.sort((a, b) => parseInt(a.code.toString()) - parseInt(b.code.toString()))

  const groupedData = data.reduce((acc, cur) => {
    const key = cur.code
    if (!acc.has(key)) acc.set(key, [])
    acc.get(key).push(cur)
    return acc
  }, new Map())

  return Array.from(groupedData.values()).map((entries, idx) => {
    const summary = entries.reduce(
      (acc: TableData, cur: TableData) => {
        acc[cur.year] = formatter.format(Math.ceil(cur.summary)) + ' ₸'
        return acc
      },
      years.reduce((acc, cur) => ({ ...acc, [cur]: formatter.format(Math.ceil(0)) + ' ₸' }), {}),
    )
    return {
      code: entries[0]?.code || '-',
      name: entries[0]?.name || '-',
      id: idx + 1,
      ...summary,
    }
  })
}

export function FinancesTabs({
  isFull: $isFull,
  person,
  finances,
  for: $data,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull'> & { finances: Record<string, any> }) {
  const [isFull, data] = useUnit([$isFull, $data])
  const locale = useLocale()
  const t = useTranslations()
  const deductionTableHeader = useMemo(
    () =>
      [
        ...(t.raw('deduction_default_head') || []),

        ...Array.from(new Set(Array.from(finances.deductionsTable.values()).map((v: any) => v.year)))
          .sort((a, b) => a - b)
          .map((v) => ({
            header: v,
            accessorKey: `${v}`,
          })),
      ].map((h) => ({
        ...h,
        size: h.accessorKey.includes('name') ? 256 : h.accessorKey.includes('code') ? 64 : 192,
      })),
    [t, finances.deductionsTable],
  )

  const deductionsList = useMemo(
    () => toTableTaxes(locale, Array.from(finances.deductionsTable.values())) || undefined,
    [finances.deductionsTable, locale],
  )
  const accrualsTableHeader = useMemo(
    () =>
      [
        ...(t.raw('deduction_default_head') || []),

        ...Array.from(new Set(Array.from(finances.accrualsTable.values()).map((v: any) => v.year)))
          .sort((a, b) => a - b)
          .map((v) => ({
            header: v,
            accessorKey: `${v}`,
          })),
      ].map((h) => ({
        ...h,
        size: h.accessorKey.includes('name') ? 256 : h.accessorKey.includes('code') ? 64 : 192,
      })),
    [t, finances.accrualsTable],
  )

  const accrualsList = useMemo(
    () => toTableTaxes(locale, Array.from(finances.accrualsTable.values())) || undefined,
    [finances.accrualsTable, locale],
  )

  const [taxDeclarationsTable, totalTaxDeclarations] = useStoreMap({
    store: $taxDeclarations,
    fn: (taxDeclarations) => {
      const table =
        taxDeclarations?.tax_declarations?.map((declaration) => ({
          ...declaration,
          amount: new Intl.NumberFormat(locale).format(Math.ceil(declaration.amount || 0)) + ' ₸',
        })) || []
      const total = sumBy(taxDeclarations?.tax_declarations, 'amount')
      return [table, total]
    },
    keys: [data?.id],
  })

  const handleExportDeductions = async () => {
    const schema = deductionTableHeader.map((item) => ({
      type: String,
      column: item.header.toString(),
      width: item.size,
      value: (data: (typeof deductionsList)[number]) => data[item.accessorKey].toString(),
    }))

    await dataToExcel(deductionsList, { fileName: 'deductions', schema })
  }

  return (
    <Tabs.Root
      defaultValue="deductions"
      className={clsx('max-w-screen lg:max-w-screen col-span-full row-auto grid grid-cols-8')}
    >
      <Tabs.List className={clsx('col-span-5 mb-0 flex list-none flex-row flex-wrap border-b pt-3')}>
        {tabRules.map((tab) => {
          const tabComponent = (
            <Tabs.Trigger
              value={tab.value}
              key={tab.value}
              className={clsx(
                'mr-2 block flex-auto border-b-2 border-b-transparent px-2 py-3 text-center text-sm font-medium  leading-normal text-primary duration-150 last:mr-0 hover:text-flamingo data-[state=active]:border-gray-400',
              )}
            >
              {t(tab.title)}
            </Tabs.Trigger>
          )

          if (tab.forPerson) {
            return person !== undefined ? person && tabComponent : tabComponent
          }

          return tabComponent
        })}
      </Tabs.List>

      <Tabs.Content value="deductions" className="max-w-screen lg:max-w-screen col-span-full row-auto grid grid-cols-8">
        <div className="relative col-span-full my-6 grid w-full min-w-0 break-words">
          <div className="col-span-6 grid grid-cols-12 gap-2 md:col-span-4">
            <DataSection title={t('tax_deductions_summary')}>
              <SafeView for={finances.totalDeductions} otherwise={t('no_information')}>
                {new Intl.NumberFormat(locale, {
                  notation: 'compact',
                  compactDisplay: 'short',
                }).format(finances.totalDeductions) + ` ₸`}
              </SafeView>
            </DataSection>

            <DataSection title={t('details')}></DataSection>
          </div>
        </div>
        <div className="col-span-full">
          {Object.keys(finances?.deductionsGraphDataByYear)?.length > 0 && (
            <Visualization data={finances.deductionsGraphDataByYear} title="deduction_in" />
          )}
          {deductionsList && deductionsList?.length > 0 && (
            <VirtualizedTable
              list={deductionsList}
              columns={deductionTableHeader}
              mocked={!isFull}
              partialMocked
              collapsible
            />
          )}
          <div className="text-white pt-4">
            <ShadcnButton className="bg-blue-500" onClick={handleExportDeductions}>
              {t(`add_on_excel`)}
            </ShadcnButton>
          </div>
        </div>
      </Tabs.Content>
      <Tabs.Content value="accruals" className="max-w-screen lg:max-w-screen col-span-full row-auto grid grid-cols-8">
        <div className="relative col-span-full my-6 grid w-full min-w-0 break-words">
          <div className="col-span-6 grid grid-cols-12 gap-2 md:col-span-4">
            <DataSection title={t('tax_accruals_summary')}>
              <SafeView for={isFull} otherwise={<Placeholder withCuntrySgin withGeneration />}>
                <SafeView for={finances.totalAccruals} otherwise={t('no_information')}>
                  {new Intl.NumberFormat(locale, {
                    notation: 'compact',
                    compactDisplay: 'short',
                  }).format(finances.totalAccruals) + ` ₸`}
                </SafeView>
              </SafeView>
            </DataSection>

            <DataSection title={t('details')}>
              <SafeView for={!isFull}>
                <Placeholder withCuntrySgin withGeneration />
              </SafeView>
            </DataSection>
          </div>
        </div>
        <div className="col-span-full">
          <SafeView for={isFull && Object.keys(finances.accrualsGraphDataByByYear || {})}>
            <Visualization data={finances.accrualsGraphDataByByYear} title="accruals_in" />
          </SafeView>
          {accrualsList && accrualsList?.length > 0 && (
            <VirtualizedTable
              list={accrualsList}
              columns={accrualsTableHeader}
              mocked={!isFull}
              partialMocked
              collapsible
            />
          )}
        </div>
      </Tabs.Content>
      <Tabs.Content
        value="declarations"
        className="max-w-screen lg:max-w-screen col-span-full row-auto grid grid-cols-8"
      >
        <div className="relative col-span-full my-6 grid w-full min-w-0 break-words">
          <div className="col-span-6 grid grid-cols-12 gap-2 md:col-span-4">
            <DataSection title={t('tax_declarations')}>
              <SafeView for={isFull} otherwise={<Placeholder withCuntrySgin withGeneration />}>
                <SafeView for={totalTaxDeclarations} otherwise={t('no_information')}>
                  {new Intl.NumberFormat(locale, {
                    notation: 'compact',
                    compactDisplay: 'short',
                  }).format(totalTaxDeclarations) + ` ₸`}
                </SafeView>
              </SafeView>
            </DataSection>
          </div>
        </div>
        <div className="col-span-full">
          <SafeView for={taxDeclarationsTable}>
            <VirtualizedTable list={taxDeclarationsTable} columns={t.raw('tax_regime_head')} mocked={!isFull} />
          </SafeView>
        </div>
      </Tabs.Content>
    </Tabs.Root>
  )
}
