import { createEvent, createStore, sample } from 'effector'
import _ from 'lodash'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { delay } from 'patronum'
import { RequiredDeep } from 'type-fest'

import { exportCompaniesFx, findCompaniesFx, getSearchLimitsByIdentifierFx, loadAreasFx } from '@/api'
import { multipleFacets as baseMultipleFacets, filterKeys } from '@/constants'
import fmt from '@/lib/fmt'
import { $qParams, downloadFx, loadAuthenticatedUser } from '@/shared-events'

import type { Nullable } from '@type.utils'
import { snapshot } from 'patronum/snapshot'
import { RequestParams, ResponseType, UnwrapApiResponse } from 'src/shared/api/model'

const defaultFacets = {
  incorporation_date: [
    { name: 'gte', value: '' },
    { name: 'lte', value: '' },
  ],
}

export const companiesFind = createEvent<{
  data: RequestParams<'/business/search/highlights', 'post'>
  query: Record<string, any>
  headers: Record<string, any> | null
}>()

export const $companiesFindPending = findCompaniesFx.pending

export const $preparedFilters = createStore<RequestParams<'/business/search/highlights', 'post'>['filters']>({})
export const $filteredCompanies = createStore<UnwrapApiResponse<'/business/search/highlights', 'post'>['companies']>([])
export const $areas = createStore<{ value: string; name: string }[]>([])
export const $facets = createStore<Record<string, FacetValues[]>>(defaultFacets)
export const $totalFilteredCompaniesPages = createStore<number>(0)
export const $appliedFacets = createStore<Facet[]>([])
export const $isExportSettingsOpened = createStore(false)
export const appliedFacetsCalculated = createEvent<NextParsedUrlQuery>()
export const areasLoaded = createEvent<{ jurisdiction: string; lang: string }>()
export const exportSettingsOpenedChanged = createEvent<boolean>()
export const $isExcelLoaded = createStore(false)
export const $withContacts = createStore(false)
export const withContactsChanged = createEvent<boolean>()
export const $companiesToExportCount = createStore(14999)
export const companiesToExportCountChanged = createEvent<number>()
export const exportDownloaded = createEvent()
export const $searchLimitsByIdentifier = createStore<Nullable<ResponseType<'/limits', 'get'>['count']>>(null)
export const getSearchLimitsByIdentifier = createEvent()
export const requestChanged = createEvent<boolean>()

export const $page = createStore<number>(1)
export const $prevRequestData = createStore<{
  data: RequestParams<'/business/search/highlights', 'post'>
  query: Record<string, any>
  headers: Record<string, any>
} | null>(null)
export const resetFiltersAndPage = createEvent<void>()
const $requestData = snapshot({
  source: $prevRequestData,
  clock: companiesFind,
})

$filteredCompanies.on(resetFiltersAndPage, () => [])
$page.on(resetFiltersAndPage, () => 1)

sample({
  clock: companiesFind,
  source: $requestData,
  filter: (prev) => prev !== null,
  fn: (prev, cur) => {
    const prevFilters = Object.entries(prev?.data.filters as object).filter(([k, v]) => Boolean(v))
    const curFilters = Object.entries(cur.data.filters as object).filter(([k, v]) => Boolean(v))
    const filtersChanged =
      JSON.stringify(prevFilters) !== JSON.stringify(curFilters) || prev?.query.query !== cur.query.query

    if (filtersChanged) {
      resetFiltersAndPage() // сбросить фильтры и страницу
    }

    return filtersChanged
  },
  target: requestChanged,
})

// Обновление $prevRequestData после сброса
sample({
  //@ts-ignore
  clock: companiesFind,
  target: $prevRequestData,
})

$filteredCompanies.on(requestChanged, (prev, cur) => {
  return cur ? [] : prev
})
$page.on(requestChanged, (prev, cur) => (cur ? 1 : prev))
$page.on(findCompaniesFx.doneData, (prev, _) => prev + 1)

sample({
  clock: findCompaniesFx.done,
  source: [$filteredCompanies, $prevRequestData] as const,
  fn: ([companies, prevRequestData], { params, result }) => {
    let temporaryData: unknown[] = []

    const shouldSave = params.query?.query === prevRequestData?.query.query

    if (shouldSave) {
      temporaryData = [...companies]
    }

    return _.uniqBy(
      // @ts-ignore
      [...temporaryData, ..._.map(result.business, ({ company, highlights }) => ({ ...company, highlights }))],
      'id',
    )
  },
  target: $filteredCompanies,
})

sample({
  clock: exportSettingsOpenedChanged,
  target: $isExportSettingsOpened,
})

sample({
  clock: withContactsChanged,
  target: $withContacts,
})

sample({
  clock: companiesToExportCountChanged,
  target: $companiesToExportCount,
})

sample({
  clock: companiesFind,
  source: $facets,
  filter: (facets, data) => {
    const page = data.query.page && data.query.page > 1 ? data.query.page : 1
    const limit = data.query.limit
    const jurisdiction = data.data.filters?.jurisdiction || ['kz']
    const count =
      (facets?.jurisdiction as Array<{ value: string; count: number }>)?.find((x) => x.value === jurisdiction[0])
        ?.count || Number.MAX_VALUE

    const offset = (page - 1) * limit

    return offset < Math.min(count, 100) + 15
  },
  fn: (_, data) => {
    return data
  },
  target: findCompaniesFx,
})

sample({
  clock: companiesFind,
  fn: ({ data }) => data.filters,
  target: $preparedFilters,
})

sample({
  clock: areasLoaded,
  fn: ({ jurisdiction, lang }) => ({
    path: { jurisdiction },
    headers: {
      'Accept-Language': lang,
    },
  }),
  target: loadAreasFx,
})

sample({
  clock: loadAreasFx.doneData,
  filter: (
    areas: ResponseType<'/business/locations/states/{jurisdiction}', 'get'>,
  ): areas is RequiredDeep<ResponseType<'/business/locations/states/{jurisdiction}', 'get'>> =>
    Boolean(areas?.states?.length),
  fn: ({ states }) => {
    return states.map((e: any) => ({
      name: fmt.toProperCase(e.split('ГОРОД')[0]),
      value: e.toUpperCase(),
    }))
  },
  target: $areas,
})

sample({
  clock: findCompaniesFx.doneData,
  fn: ({ filters }) => {
    return _.reduce(
      filters,
      (acc: Record<string, any>, { filter_name_for_search, filter_values }) => {
        if (filter_name_for_search) {
          acc[filter_name_for_search as keyof typeof acc] = filter_values
          return acc
        }

        return acc
      },
      $facets.defaultState,
    )
  },
  target: $facets,
})

sample({
  clock: findCompaniesFx.doneData,
  filter: ({ count }) => Boolean(count),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  fn: ({ count }) => Math.ceil(count! / 15),
  target: $totalFilteredCompaniesPages,
})

sample({
  clock: appliedFacetsCalculated,
  source: { facets: $facets, areas: $areas },
  filter: ({ facets }, query) => !!query && !!facets,
  fn: ({ facets, areas }, query) => {
    return _.map(query, (val: string, key: (typeof filterKeys)[number]): Facet => {
      const multipleFacets = baseMultipleFacets[key as keyof typeof baseMultipleFacets] ?? null
      const isMultiple = multipleFacets?.length > 0
      const arr = _.split(val?.toUpperCase() ?? '', ',')

      let collection = facets[key] || []

      const inCollection = collection.find(
        (item) => item.value.toString().toUpperCase() === val.toString().toUpperCase(),
      )

      if (['revenue', 'fin_taxes'].includes(key)) {
        if (!arr?.length) return [[], key]

        const [min, max] = arr

        return [[{ name: key, value: `${min}-${max}`, count: 0 }], key]
      }

      if (
        Array<filterKey>('legal_form', 'contact_type', 'addresses.state', 'main_industry_code', 'inactive').includes(
          key,
        )
      ) {
        return [inCollection ? [inCollection] : [], key]
      }

      if (isMultiple) {
        collection = multipleFacets.map((f) => {
          const applied = facets[key]?.find((facet) => facet.name.toUpperCase() === f.name.toUpperCase())

          if (applied) return applied

          return f as FacetValues
        })
      }

      const currentFilter = collection.filter(
        (item) => arr.includes(item.value.toUpperCase()) || (item.count && arr.includes(item.count.toString())),
      )

      return [currentFilter || [], key]
    }) as unknown as Facet[]
  },
  target: $appliedFacets,
})

sample({
  clock: exportDownloaded,
  source: {
    facets: $preparedFilters,
    withContacts: $withContacts,
    query: $qParams,
    companiesToExportCount: $companiesToExportCount,
  },
  fn: ({ facets, withContacts, query, companiesToExportCount }) => {
    return {
      data: {
        file_type: 'excel' as const,
        type: 'companies' as const,
        filters: { ...facets, query: query?.value, limit: [`${companiesToExportCount}`] } as any,
        with_contacts: withContacts,
      },
    }
  },
  target: exportCompaniesFx,
})

sample({
  clock: exportCompaniesFx.doneData,
  source: $qParams,
  fn: (params, file) => ({ jurisdiction: params?.state ?? '', file, search: params?.value ?? '' }),
  target: downloadFx,
})

sample({
  clock: downloadFx.doneData,
  fn: () => true,
  target: [$isExcelLoaded, loadAuthenticatedUser],
})

const cancelExcelLoaded = delay({ source: downloadFx.doneData, timeout: 300 })

sample({
  clock: cancelExcelLoaded,
  fn: () => false,
  target: $isExcelLoaded,
})

sample({
  clock: getSearchLimitsByIdentifier,
  fn: () => ({}),
  target: getSearchLimitsByIdentifierFx,
})

sample({
  clock: getSearchLimitsByIdentifierFx.pending,
  fn: () => null,
  target: $searchLimitsByIdentifier,
})

sample({
  clock: getSearchLimitsByIdentifierFx.doneData,
  fn: ({ count }) => count,
  target: $searchLimitsByIdentifier,
})
