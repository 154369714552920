import { createContext, useContext, useState } from 'react'
import { Notification } from './notification'
import { NotificationType } from './types'

type ContextType = {
  showNotification: (payload: NotificationType) => void
  hideNotification: (id: string) => void
}

const NotificationContext = createContext({} as ContextType)

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<NotificationType[]>([])

  const showNotification: ContextType['showNotification'] = (payload) => {
    setState((prev) => {
      if (prev.find((item) => item.id === payload.id)) return prev
      return [...prev, payload]
    })
  }

  const hideNotification: ContextType['hideNotification'] = (id: string) => {
    setState((prev) => prev.filter((notification) => notification.id !== id))
  }

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      <div className="fixed right-1/2 translate-x-1/2 top-4 will-change-transform z-40 flex flex-col items-center gap-2">
        {state.map((notification) => (
          <Notification {...notification} key={notification.id} />
        ))}
      </div>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotificationContext = () => useContext(NotificationContext)
