import { $userProfile } from '@entities/user'
import { useNotification } from '@shared/ui/notification'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

export function SubscriptionNotify() {
  const user = useUnit($userProfile)
  const t = useTranslations()

  useNotification({
    condition: !!user && user.plan_id === 0,
    type: 'error',
    content: <span>{t('user_notifications_subscription_empty')}</span>,
  })

  return null
}
