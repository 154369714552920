'use client'
import { useField } from 'effector-forms'
import { useUnit } from 'effector-react'

import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'

import { useEffect, useState } from 'react'

import { extractPlanInfoById, ROUTES, Subscription } from '@/constants'
import { $userProfile, OrdersCountProgress, SessionsCountProgress } from '@/entities/user'
import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { Nullable } from '@/T'
import { EditableField, ShadcnButton } from '@/ui'

import { cancelSubscriptionFxError, cancelSubscriptionFxPending } from '@shared/api'
import { Alert, AlertDescription, AlertTitle } from '@shared/ui/alert'
import Icon from '@shared/ui/icon'
import { useNotification } from '@shared/ui/notification'
import { subscriptionChosen } from '../../subscriptions/model'
import { $$profileForm, cancelSubscriptionEvent, profileFieldUpdated } from '../profile/model'
import { resendSms } from './model'
import Modal from './personal-modal'

interface SubscriptionTry {
  tried_at: number
  subscription: Subscription
}

const subscriptionTryTimeRange = 1.8e6

const SubscriptionBanner = () => {
  const t = useTranslations()

  const [_, chooseSubscription] = useUnit([$userProfile, subscriptionChosen])

  const [lastSubscriptionTryAt, setLastSubscriptionTryAt] = useState<Nullable<SubscriptionTry>>(null)

  useEffect(() => {
    setLastSubscriptionTryAt(JSON.parse(localStorage.getItem('subscribe') || 'null'))
  }, [])

  const cancelSubscriptionTry = () => {
    localStorage.removeItem('subscribe')
    setLastSubscriptionTryAt(null)
  }

  const continueSubscriptionPaying = () => {
    if (!lastSubscriptionTryAt) return
    localStorage.removeItem('subscribe')
    chooseSubscription(lastSubscriptionTryAt.subscription)
  }

  const isSubscriptionTryInRange =
    lastSubscriptionTryAt && Date.now() <= lastSubscriptionTryAt.tried_at + subscriptionTryTimeRange

  return (
    <SafeView for={isSubscriptionTryInRange}>
      <div className="bordered col-span-full mb-4 flex w-full items-end justify-between rounded bg-red-400 px-4 py-5 text-white">
        <div>
          <h1 className="text-2xl">
            {t(`ready_to_go_plan`)} {lastSubscriptionTryAt?.subscription?.title}?
          </h1>
          <p>{t(`for_go_to_plan_banner`)}</p>
          <p>
            {t(`change_mind`)} {t(`then`)} —{' '}
            <span className="cursor-pointer text-black" onClick={cancelSubscriptionTry}>
              {t(`cancel_plan`)}
            </span>
          </p>
        </div>
        <button onClick={continueSubscriptionPaying} className="mt-4 rounded bg-white p-2 text-black">
          {t(`yes`)}, {t(`continue`).toLowerCase()}
        </button>
      </div>
    </SafeView>
  )
}

export const PersonalAreaScreen = () => {
  const t = useTranslations()
  const [subscribeCanceled] = useState(false)

  const [user, updateProfile, sendSms] = useUnit([$userProfile, profileFieldUpdated, resendSms])

  const [cancelSubscription, isSubscriptionCancelling, subscriptionCancelError] = useUnit([
    cancelSubscriptionEvent,
    cancelSubscriptionFxPending,
    cancelSubscriptionFxError,
  ])

  const emailField = useField($$profileForm.fields.email)

  const onEditEnd = (v: string) => {
    updateProfile({
      field: 'email',
      value: v,
    })
  }

  const handleModalConfirm = () => {
    cancelSubscription()
  }

  const isSubscriptionEndingOrNotUpdated =
    Math.floor((new Date(user?.end_date || '').getTime() - new Date().getTime()) / (1000 * 3600 * 24)) <= 3 ||
    !user?.next_bill_date

  useNotification({
    condition: !!user && !user.phone_verified,
    type: 'error',
    content: (
      <ShadcnButton className="text-white" variant="link" onClick={sendSms}>
        {t('user_resend_sms')}
      </ShadcnButton>
    ),
  })

  useNotification({
    condition: !!user && !user.email_verified,
    type: 'error',
    content: (
      <ShadcnButton className="text-white" variant="link">
        {t('user_resend_email')}
      </ShadcnButton>
    ),
  })

  return (
    <SafeView for={user} withProps otherwise={null}>
      {(user) => (
        <>
          <SubscriptionBanner />
          {!user.email_verified && (
            <Alert variant="destructive" className="col-span-full">
              <AlertTitle className="flex flex-row items-center space-x-2">
                <Icon name="mail-minus" size={16} />
                <span>{t('email_not_verified_title')}</span>
              </AlertTitle>
              <AlertDescription>{t('email_not_verified_description')}</AlertDescription>
            </Alert>
          )}
          <div className="col-span-full grid grid-cols-4 border-b pb-4">
            <h1 className="col-span-full mb-4 font-stem text-2xl">
              {t('hello')}
              {user.name || user.email}
            </h1>
            <div className="col-span-full grid grid-cols-4 border-b py-4">
              <h2 className="col-span-full text-lg md:col-span-2 ">{t('lk_email')}</h2>
              <h2 className="col-span-full flex flex-wrap items-center justify-between space-x-4 md:col-span-2">
                <EditableField
                  editTitle={t('edit_email')}
                  onEditEnd={onEditEnd}
                  label={t('email')}
                  type="email"
                  name="email"
                  onChange={(e) => emailField.onChange(e.target.value)}
                  value={emailField.value}
                >
                  {user.email}
                </EditableField>
              </h2>
            </div>
            <h2 className="col-span-full my-auto pt-4 text-lg md:col-span-2">{t('subscribe_type')}</h2>

            <h2 className="col-span-full my-auto space-x-2 pt-4 md:col-span-2">
              <SafeView
                for={user.plan_id}
                otherwise={
                  <Link
                    href={ROUTES.PRICING}
                    className="text-md rounded bg-blue-500 p-3 font-medium text-white transition hover:bg-blue-600"
                    rel="nofollow"
                  >
                    {t('subscribe')}
                  </Link>
                }
              >
                <span className="py-2 font-medium text-blue-400">{extractPlanInfoById(user.plan_id)?.title}</span>
                <SafeView
                  for={isSubscriptionEndingOrNotUpdated}
                  otherwise={
                    <SafeView for={subscribeCanceled}>
                      <Link
                        href={ROUTES.PRICING}
                        className="text-md rounded bg-gray-500 p-2 font-medium text-white transition hover:bg-gray-600"
                        rel="nofollow"
                      >
                        {t('subscription_canceled')}
                      </Link>
                    </SafeView>
                  }
                >
                  <Link
                    href={ROUTES.PRICING}
                    className="text-md rounded bg-blue-500 p-3 font-medium text-white transition hover:bg-blue-600"
                    rel="nofollow"
                  >
                    {t('update_subscribe')}
                  </Link>
                </SafeView>
                {!(user && user.card_type && user.card_first_six && user.card_last_four) && (
                  <Modal onConfirm={handleModalConfirm}>
                    <button className="text-md rounded bg-blue-500 p-2 font-medium text-white transition-all hover:bg-blue-600">
                      {t('unsubscribe')}
                    </button>
                  </Modal>
                )}
              </SafeView>
            </h2>
          </div>
          <SafeView for={user.card_type && user.card_first_six && user.card_last_four}>
            <div className="col-span-full grid grid-cols-4 items-center border-b pb-4">
              <h2 className="col-span-full text-lg md:col-span-2 ">{t('payment_type')}</h2>
              <div className="col-span-full flex flex-row items-center space-x-2 md:col-span-2">
                <Image
                  src={`/icons/payment-methods/${user?.card_type?.toLowerCase()}.svg`}
                  alt={`${user.card_type} icon`}
                  width={36}
                  height={36}
                />
                <span className="cursor-pointer">
                  {user.card_first_six} •••• {user.card_last_four}
                </span>
                {user.next_bill_date && (
                  <Modal onConfirm={handleModalConfirm}>
                    <button className="text-md rounded bg-blue-500 p-2 font-medium text-white transition-all hover:bg-blue-600">
                      {t('unsubscribe')}
                    </button>
                  </Modal>
                )}
              </div>
            </div>
          </SafeView>
          <div className="col-span-full grid grid-cols-4 border-b pb-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('subscribe_status')}</h2>
            <h2 className="col-span-full space-x-4 md:col-span-2">
              {user.status === 'Активна' ? t('subscribe_active') : t('wait_for_buy')}
            </h2>
          </div>
          <SafeView for={user && user.plan_id !== 0} otherwise={null}>
            <div className="col-span-full grid grid-cols-4 border-b pb-4">
              <h2 className="col-span-full text-lg md:col-span-2 ">{t('next_payment_date')}</h2>
              <h2 className="col-span-full space-x-4 md:col-span-2">
                <SafeView for={user.plan_id !== 0} otherwise={t('not_buy')}>
                  <SafeView for={user.next_bill_date} otherwise={t('next_bill_cancel')}>
                    {fmt.date(new Date(user.next_bill_date))}
                  </SafeView>
                </SafeView>
              </h2>
            </div>
          </SafeView>
          <div className="col-span-full grid grid-cols-4 border-b pb-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('suscribe_end')}</h2>
            <h2 className="col-span-full md:col-span-2">
              {user?.plan_id === 0 && !user?.end_date ? (
                t('not_buy')
              ) : (
                <>
                  {new Date(new Date(user?.end_date)).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                  <span className="text-gray-500">
                    {' '}
                    (
                    {Math.round(
                      (Date.now() -
                        //@ts-ignore
                        new Date(user.end_date)) /
                        (1000 * 60 * 60 * 24),
                    )
                      .toString()
                      .split('-')}{' '}
                    {t(`days`)})
                  </span>
                </>
              )}
            </h2>
          </div>
          <div className="col-span-full grid grid-cols-4 border-b pb-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('orders_count')}</h2>
            <h2 className="col-span-full md:col-span-2">
              <OrdersCountProgress />
            </h2>
          </div>
          <div className="col-span-full grid grid-cols-4 border-b pb-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('active_sessions')}</h2>
            <h2 className="col-span-full md:col-span-2">
              <SessionsCountProgress />
            </h2>
          </div>
        </>
      )}
    </SafeView>
  )
}
