import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { FormEvent } from 'react'

import { $isForgetPasswordLoading } from '@/api'
import { Back, CircularProgress, FloatInput } from '@/ui'

import { $email, $showSuccessMessage, emailChanged, forgetPasswordSent } from './model'

export const ForgetPasswordScreen = () => {
  const t = useTranslations()
  const locale = useLocale()

  const [sendForgetPassword, setEmail, email, isLoading, showSuccessMessage] = useUnit([
    forgetPasswordSent,
    emailChanged,
    $email,
    $isForgetPasswordLoading,
    $showSuccessMessage,
  ])

  const submitHandler = (e: FormEvent) => {
    e.preventDefault()
    sendForgetPassword()
  }

  return (
    <main className="container flex h-screen flex-col items-center justify-center">
      <Link locale={locale} href="/" className="font-aeroport text-2xl">
        Statsnet
      </Link>
      <p className="text-lg sm:text-xl">{t('restore_password')}</p>
      <form className="flex w-full max-w-sm flex-col bg-transparent" onSubmit={(e) => submitHandler(e)}>
        <FloatInput
          label="Email"
          type="email"
          name="email"
          className={'rounded-md border-b'}
          onChange={(e: any) => setEmail(e.target.value)}
          value={email}
        />

        <button
          className="btn mt-5 rounded-md bg-blue-500 p-3.5 text-white outline-none duration-300 hover:bg-blue-600 focus:outline-none  "
          type="submit"
          disabled={isLoading}
        >
          {' '}
          {isLoading ? (
            <div className="mx-auto h-4 w-4">
              <CircularProgress color="white" />
            </div>
          ) : (
            t('restore_password_link_text')
          )}
        </button>
        {showSuccessMessage && <p className="py-2 text-green">{t(`forget_password_link_send_text`)}</p>}
      </form>

      <Back />
    </main>
  )
}
