import { createStore, sample } from 'effector'
import { combineEvents } from 'patronum'

import { loadCompanyDataFx, loadCompanyTaxRegimeFx } from '@/api'
import { createBindingForVisibility } from '@/features'
import { ResponseType } from 'src/shared/api/model'

export const { $inView, $isLoaded, visibilityChanged, $companyId, $shouldBeLoaded } = createBindingForVisibility()

export const $taxDeclarations = createStore<ResponseType<'/business/{id}/tax_declarations', 'get'>>({})

export const $companyData = createStore<ResponseType<'/business/{id}/data/view/meta', 'get'>>({})

sample({
  clock: $inView,
  source: { isLoaded: $isLoaded, companyId: $companyId, shouldBeLoaded: $shouldBeLoaded },
  filter: ({ isLoaded, companyId, shouldBeLoaded }, inView) => shouldBeLoaded && inView && !isLoaded && !!companyId,
  fn: ({ companyId }) => ({
    path: {
      id: (companyId ?? 0) as string,
    },
  }),
  target: [loadCompanyTaxRegimeFx, loadCompanyDataFx],
})

sample({
  clock: combineEvents({
    events: [loadCompanyTaxRegimeFx.done, loadCompanyDataFx.done],
  }),
  fn: () => true,
  target: $isLoaded,
})

sample({
  clock: loadCompanyDataFx.doneData,
  target: $companyData,
})

sample({
  clock: loadCompanyTaxRegimeFx.doneData,
  target: $taxDeclarations,
})
