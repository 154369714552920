import writeXlsxFile, { Row, Schema } from 'write-excel-file'

const DEFAULT_CHAR_WIDTH = 7

const getWidth = (width?: number) => (width ? width / DEFAULT_CHAR_WIDTH : 40)

type OptionsType<T> = {
  fileName: string
  schema: Schema<T>
}

export const dataToExcel = async <T extends Row>(data: Row[], options: OptionsType<T>) => {
  const schema = options.schema.map((item) => ({ ...item, width: getWidth(item.width) }))

  await writeXlsxFile(data, { fileName: `${options.fileName}.xlsx`, schema })
}
